:root {
    --mobinov-blue-color: #0060AE;
    --mobinov-darkblue-color: #004D8B;
    --mobinov-disabled-color: #629CCB;
    --mobinov-grey-color: #4D4D4D;
    --mobinov-darkgrey-color: #262626;
}

* {
    font-family: 'Roboto';
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex-grow: 1;
}

.background-img {
    background-image: url('../assets/images/background.svg');
    background-repeat: repeat-y;
    background-size: cover;
}

.btn-primary {
    background-color: var(--mobinov-blue-color) !important;
    border-color: var(--mobinov-blue-color) !important;
}


.white-btn {
    border: 2px solid #0060AE;
    color: #0060AE;
    font-weight: 500;
    font-size: 20px;
}

.btn-primary:hover,
.white-btn:hover {
    background-color: var(--mobinov-darkblue-color) !important;
    border-color: var(--mobinov-darkblue-color) !important;
}

.btn-primary:disabled,
.white-btn:disabled {
    background-color: var(--mobinov-disabled-color) !important;
    border-color: var(--mobinov-disabled-color) !important;
}


.mobinov-blue-color {
    color: var(--mobinov-blue-color);
}

.mobinov-darkblue-color {
    color: #004075;
}

.mobinov-blue-bg {
    background: var(--mobinov-blue-color);
}

.mobinov-grey-color {
    color: var(--mobinov-grey-color);
}

.mobinov-darkgrey-color {
    color: var(--mobinov-darkgrey-color);
}

.mobinov-grey-bg {
    background: rgb(230, 230, 230)
}

.mobinov-header {
    font-weight: bold;
    font-size: 33px;
}

.mobinov-title {
    font-weight: 600;
    font-size: 25px;
}

.mobinov-subtitle {
    font-weight: 600;
    font-size: 21px;
}

.mobinov-formlabel {
    font-weight: 500;
    font-size: 20px;
}

.mobinov-text {
    font-size: 20px;
    text-align: justify !important;
    text-justify: inter-word;
}

.mobinov-text-nojustify {
    font-size: 20px;
}

.mobinov-text-small {
    font-size: 15px;
}

.link {
    color: var(--mobinov-grey-color);
}

.link:hover {
    color: var(--mobinov-blue-color);
}

.hidden {
    display: none !important;
}

.modal-background {
    background-color: rgb(230, 230, 230);
}

#loading {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
}

#loading-image {
    z-index: 100;
    width: 6%;
    margin-top: 45vh;
}

#recaptcha-error {
    display: none;
    color: #dc3545;
    font-size: .875em;
}