#statistic .container {
    max-width: 1060px;
}

.img-statistics-container {
    height: 160px;
}

.img-responsive.statics {
    padding: 20px 40px;
    width: 172px;
}

.info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10%;
}

.individual {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 170px;
}

.statistic-title {
    color: #6C6C6C;
    text-align: center;
    min-height: 100px;
    margin: unset;
}

.result {
    color: #0060AE;
    font-weight: 600;
}

.screenshot {
    width: 600px;
}

@media only screen and (max-width: 576px) {
    .screenshot {
        width: 450px;
    }
}

.panel {
    margin-top: 150px;
    position: relative;
}