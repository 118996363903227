.date {
    text-align: right;
    margin-right: 10px;
    font-size: 18px;
}

.title {
    color: #0C1053;
    font-weight: bold;
}

.link_news {
    text-decoration: none;
    cursor: pointer;
    color: #212529;
    width: 100%;
    margin: 0;
}

.card-new {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.photo-news {
    max-width: 100%;
    max-height: 500px;
}

.photo-news-container {
    max-width: 512px;
    max-height: 400px;
}

@media only screen and (max-width: 992px) {
    .card-new {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
  }