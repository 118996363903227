#opportunities {
  height: 97%;
  width: 90%;
}

#opportunities-list {
  overflow-y: auto;
}

.opportunity-icon {
  background: #DBDFE3;
}

.opportunity-title {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
}

.opportunity-subtitle {
  font-weight: 700;
  font-size: 18px;
}

.opportunity-bar {
  width: 100px;
  height: 20px;
  margin-left: 6px;
  display: inline-block;
}

.green {
  background: #7BC950;
}

.yellow {
  background: #EEF15F;
}

.red {
  background: #8A3024;
}

#opportunity {
  height: 43vh;
  font-size: 20px;
}

.opportunity-description {
  font-size: 20px;
  font-weight: 400;
  width: 90%;
}

.opportunity-bold {
  font-weight: 700;
  font-size: 20px;
}

.opportunity-pdf {
  font-size: small;
  font-weight: 700;
  line-break: anywhere;
}