.carousel-portugal {
    color: rgba(255, 255, 255, 0.7);
}

.carousel-collaborate {
    color: transparent;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: white;
}

.carousel-mobinov {
    font-weight: bold;
}

.sectioninfo h5 {
    font-weight: 700;
    color: #0060AE;
}

.homesection-1, .homesection-3 {
    background-color: #F5F5F7;
}

.btn:focus {
    outline: none;
    box-shadow: none;
  }

  .btn.btn-primary:active:hover,
.btn.btn-primary:hover {
    border-color: none;
    background-color: none;
}

.homesection-2 {
    background-color: #E6E6E6;
}

.homesection-3 .card {
    background-color: #E6E6E6;
    color: #4D4D4D;
    text-align: center;
    overflow: hidden;
}

.schedule .card {
    background-color: #E6E6E6;
}

.homesection-3 .card-title {
    background-color: #E6E6E6;
    font-weight: 600;
    font-size: 18px;
    line-height: 29.3px;
}

.schedule .card-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 29.3px;
}

.homesection-3 .card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.homesection-3 .card-date {
    color: #0060AE;
    float: right;
}

.video-responsive {
    width: 75%;
    margin-inline: auto;
    padding-bottom: 42.25%;
    position: relative;
    text-align: center;
    height: 0;
}

.video-responsive iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}