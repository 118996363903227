.cluster-card-title {
    font-size: 22px;
    font-weight: 600;
}

.mobinov-card {
    width: 240px;
    height: 230px;
    margin: 17px 28px;
    background-color: #DBDFE3;
    box-shadow: 0 1px 10px rgb(0 0 0 / 25%);
}

.mobinov-card-selected {
    background-color: rgba(0, 96, 174, 0.2);
    color: var(--mobinov-blue-color);
}

.mobinov-card:hover {
    cursor: pointer;
}

.no-pointer:hover {
    cursor: default;
}

.logo-images {
    max-width: 200px;
    max-height: 130px;
    padding: 20px;
}

.cluster-card {
    width: 200px;
    height: 130px;
    margin: 30px 12px;
    background-color: rgba(230, 230, 230, 0.8);
    box-shadow: 0 1px 10px rgb(0 0 0 / 25%);
}

.cluster-card-hover:hover {
    cursor: pointer;
}

.cluster-card-selected {
    background-color: rgba(0, 96, 174, 0.15);
}

.cluster-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cluster-carousel-image {
    max-width: 400px;
    max-height: 300px;
}