@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,700);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
    --mobinov-blue-color: #0060AE;
    --mobinov-darkblue-color: #004D8B;
    --mobinov-disabled-color: #629CCB;
    --mobinov-grey-color: #4D4D4D;
    --mobinov-darkgrey-color: #262626;
}

* {
    font-family: 'Roboto';
}

.App {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.content {
    flex-grow: 1;
}

.background-img {
    background-image: url(/static/media/background.9db4e220.svg);
    background-repeat: repeat-y;
    background-size: cover;
}

.btn-primary {
    background-color: #0060AE !important;
    background-color: var(--mobinov-blue-color) !important;
    border-color: #0060AE !important;
    border-color: var(--mobinov-blue-color) !important;
}


.white-btn {
    border: 2px solid #0060AE;
    color: #0060AE;
    font-weight: 500;
    font-size: 20px;
}

.btn-primary:hover,
.white-btn:hover {
    background-color: #004D8B !important;
    background-color: var(--mobinov-darkblue-color) !important;
    border-color: #004D8B !important;
    border-color: var(--mobinov-darkblue-color) !important;
}

.btn-primary:disabled,
.white-btn:disabled {
    background-color: #629CCB !important;
    background-color: var(--mobinov-disabled-color) !important;
    border-color: #629CCB !important;
    border-color: var(--mobinov-disabled-color) !important;
}


.mobinov-blue-color {
    color: #0060AE;
    color: var(--mobinov-blue-color);
}

.mobinov-darkblue-color {
    color: #004075;
}

.mobinov-blue-bg {
    background: #0060AE;
    background: var(--mobinov-blue-color);
}

.mobinov-grey-color {
    color: #4D4D4D;
    color: var(--mobinov-grey-color);
}

.mobinov-darkgrey-color {
    color: #262626;
    color: var(--mobinov-darkgrey-color);
}

.mobinov-grey-bg {
    background: rgb(230, 230, 230)
}

.mobinov-header {
    font-weight: bold;
    font-size: 33px;
}

.mobinov-title {
    font-weight: 600;
    font-size: 25px;
}

.mobinov-subtitle {
    font-weight: 600;
    font-size: 21px;
}

.mobinov-formlabel {
    font-weight: 500;
    font-size: 20px;
}

.mobinov-text {
    font-size: 20px;
    text-align: justify !important;
    text-justify: inter-word;
}

.mobinov-text-nojustify {
    font-size: 20px;
}

.mobinov-text-small {
    font-size: 15px;
}

.link {
    color: #4D4D4D;
    color: var(--mobinov-grey-color);
}

.link:hover {
    color: #0060AE;
    color: var(--mobinov-blue-color);
}

.hidden {
    display: none !important;
}

.modal-background {
    background-color: rgb(230, 230, 230);
}

#loading {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    text-align: center;
    opacity: 0.7;
    background-color: #fff;
    z-index: 99;
}

#loading-image {
    z-index: 100;
    width: 6%;
    margin-top: 45vh;
}

#recaptcha-error {
    display: none;
    color: #dc3545;
    font-size: .875em;
}
.nav-item {
  font-family: Roboto;
  font-size: 20px;
  line-height: 23px;
  color: #6C6C6C;
}

.nav-item:hover {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}


.navbar {
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 96, 174, 0.05), rgba(0, 96, 174, 0.05)), #E6E6E6;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}


#logo {
  margin-left: 2em;
}


.nav-item:hover .sub-navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 100;
}

.nav-item:hover .block-navbar {
  display: flex;
  position: absolute;
  height: 2em;
  width: 10%;
}

.block-navbar:hover {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 100;
}


.sub-navbar:hover .sub-navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 100;
}

.sub-navbar {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 105px;
  height: 80px;
  background: #0060AE !important;
}

.sub-navbar-content a {
  margin-right: 3em;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: white;
}

.sub-navbar-content a:hover {
  text-decoration: underline;
}

#pt {
  font-weight: bold;
}

#en {
  font-weight: normal;
}

@media only screen and (max-width: 992px) {
  .sub-navbar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    height: -webkit-max-content;
    height: max-content;
    padding-left: 10%;
  }

  .sub-navbar-content a {
    line-height: 36px;
  }

  .sub-navbar {
    top: 413px;
    height: -webkit-max-content;
    height: max-content;
  }

  .nav-item:hover .block-navbar {
    height: 30vh;
    width: 100%;
  }
}
footer {
    width: 100%;
    background: #DBDFE3;

    font-style: normal;
    font-size: 10px;
    color: var(--mobinov-grey-color);
}

footer a {
    text-decoration: none;
    text-align: center;
    color: var(--mobinov-grey-color);
}

footer a:hover {
    color: var(--mobinov-blue-color);
}

.footer-link a {
    font-size: 1.8em;
    font-weight: 600;
}

.social-media-icon {
    width: 48px;
    height: 48px;
}

.footer-contacts {
    font-size: 1.6em;
    font-weight: 400;
}

.contact-icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.contact-icon.disabled {
    visibility: hidden;
}

.authorities {
    font-size: 1.8em;
    font-weight: 700;
    text-align: center;
}

.authorities img{
    width: 430px;
}

.whoarewe-pdf-button {
    min-width: 160px;
}

.whoarewe-card-header {
    background: linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7; 
    min-height: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whoarewe-card-body {
    background: linear-gradient(0deg, rgba(0, 96, 174, 0.2), rgba(0, 96, 174, 0.2)), #F5F5F7;
    min-height: 280px;
}

.whoarewe-icon {
    width: 28px;
    height: 28px;
}

.whoarewe-card-date {
    font-weight: 600;
    font-size: 22px;
}

.whoarewe-carousel {
    position: relative;
}

.whoarewe-button-group {
    position: absolute;
    width: 100%;
    top: 48%;
}

.whoarewe-carousel-icon {
    height: 30px;
}

.whoarewe-button-group button {
    background: linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7;
    -webkit-filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.3));
            filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.3));
    
    height: 50px;
    width: 35px;

    position: absolute;
}

.whoarewe-button-group button:nth-child(1) {
    left: -4%;
}

.whoarewe-button-group button:nth-child(2) {
    right: -4%;
}

.whoarewe-button-group button.disable {
    visibility: hidden;
}

@media only screen and (max-width: 992px) {
    .whoarewe-card-body {
        height: 230px; 
    }
    .whoarewe-button-group button:nth-child(1) {
        left: -6%;
    }
    
    .whoarewe-button-group button:nth-child(2) {
        right: -6%;
    }
}

  @media only screen and (max-width: 576px) {
    .whoarewe-card-text {
        font-size: 19px;
    }
}
.enterprises {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

#members .container {
    max-width: 1060px;
}

#members .row h2 {
    font-size: 25px;
}

#members .row .text-center:hover {
    color: #0060AE;
    cursor: pointer;
}

a.partner-box {
    display: inline-flex;
    padding: 30px 20px;
}

.text-selected {
    color: #0060AE;
}

button {
    /* all: unset; */
    background: transparent;
    border: 0px;
    font-size: 25px;
    font-weight: 600;
}

.members-card {
    width: 200px;
    margin: 30px 12px;
    background-color: rgba(230, 230, 230, 0.8);
    box-shadow: 0 1px 10px rgb(0 0 0 / 25%);
}
.service-card {
    background-color: #DBDFE3;
    min-height: 190px;
    vertical-align: middle;
}

.service-card:hover {
    background-color: rgba(0, 96, 174, 0.2);
}

@media only screen and (max-width: 992px) {
    .service-card {
        min-height: 200px;
    }
}

@media only screen and (max-width: 1200px) {
    .service-card {
        min-height: 236px;
    }
}
#statistic .container {
    max-width: 1060px;
}

.img-statistics-container {
    height: 160px;
}

.img-responsive.statics {
    padding: 20px 40px;
    width: 172px;
}

.info {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 10%;
}

.individual {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 170px;
}

.statistic-title {
    color: #6C6C6C;
    text-align: center;
    min-height: 100px;
    margin: unset;
}

.result {
    color: #0060AE;
    font-weight: 600;
}

.screenshot {
    width: 600px;
}

@media only screen and (max-width: 576px) {
    .screenshot {
        width: 450px;
    }
}

.panel {
    margin-top: 150px;
    position: relative;
}
.card-img-project {
    max-width: 499px;
    max-height: 298px;
    background-color: rgba(230, 230, 230, 0.8);
}

.card-project {
    max-width: 500px;
    min-height: 500px;
    max-height: 300px;
    min-height: 300px;
    align-items: center ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.date {
    text-align: right;
    margin-right: 10px;
    font-size: 18px;
}

.title {
    color: #0C1053;
    font-weight: bold;
}

.link_news {
    text-decoration: none;
    cursor: pointer;
    color: #212529;
    width: 100%;
    margin: 0;
}

.card-new {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.photo-news {
    max-width: 100%;
    max-height: 500px;
}

.photo-news-container {
    max-width: 512px;
    max-height: 400px;
}

@media only screen and (max-width: 992px) {
    .card-new {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
    }
  }
.card-gallery {
    max-width: 500px;
    min-height: 500px;
    max-height: 300px;
    min-height: 300px;
    align-items: center ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    -webkit-filter: drop-shadow(0px 0.2px 10px rgba(0, 0, 0, 0.25));
            filter: drop-shadow(0px 0.2px 10px rgba(0, 0, 0, 0.25));
}
.cluster-card-title {
    font-size: 22px;
    font-weight: 600;
}

.mobinov-card {
    width: 240px;
    height: 230px;
    margin: 17px 28px;
    background-color: #DBDFE3;
    box-shadow: 0 1px 10px rgb(0 0 0 / 25%);
}

.mobinov-card-selected {
    background-color: rgba(0, 96, 174, 0.2);
    color: var(--mobinov-blue-color);
}

.mobinov-card:hover {
    cursor: pointer;
}

.no-pointer:hover {
    cursor: default;
}

.logo-images {
    max-width: 200px;
    max-height: 130px;
    padding: 20px;
}

.cluster-card {
    width: 200px;
    height: 130px;
    margin: 30px 12px;
    background-color: rgba(230, 230, 230, 0.8);
    box-shadow: 0 1px 10px rgb(0 0 0 / 25%);
}

.cluster-card-hover:hover {
    cursor: pointer;
}

.cluster-card-selected {
    background-color: rgba(0, 96, 174, 0.15);
}

.cluster-carousel-item {
    display: flex;
    align-items: center;
    justify-content: center;
}

.cluster-carousel-image {
    max-width: 400px;
    max-height: 300px;
}
.carousel-portugal {
    color: rgba(255, 255, 255, 0.7);
}

.carousel-collaborate {
    color: transparent;
    -webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: white;
}

.carousel-mobinov {
    font-weight: bold;
}

.sectioninfo h5 {
    font-weight: 700;
    color: #0060AE;
}

.homesection-1, .homesection-3 {
    background-color: #F5F5F7;
}

.btn:focus {
    outline: none;
    box-shadow: none;
  }

  .btn.btn-primary:active:hover,
.btn.btn-primary:hover {
    border-color: none;
    background-color: none;
}

.homesection-2 {
    background-color: #E6E6E6;
}

.homesection-3 .card {
    background-color: #E6E6E6;
    color: #4D4D4D;
    text-align: center;
    overflow: hidden;
}

.schedule .card {
    background-color: #E6E6E6;
}

.homesection-3 .card-title {
    background-color: #E6E6E6;
    font-weight: 600;
    font-size: 18px;
    line-height: 29.3px;
}

.schedule .card-title {
    font-weight: 600;
    font-size: 18px;
    line-height: 29.3px;
}

.homesection-3 .card-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7; /* number of lines to show */
    -webkit-box-orient: vertical;
}

.homesection-3 .card-date {
    color: #0060AE;
    float: right;
}

.video-responsive {
    width: 75%;
    margin-inline: auto;
    padding-bottom: 42.25%;
    position: relative;
    text-align: center;
    height: 0;
}

.video-responsive iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
}
.list-button, .calendar-button {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    font-weight: 600;
    font-size: 23px;
    margin: 2px;
}

.list-button:hover, .calendar-button:hover {
    background-color: white !important;
    color: #0060AE !important;
    border-color: white !important;
}

.list-button:focus, .calendar-button:focus {
    background-color: white;
    color: #0060AE;
    border-color: white;
}

.title-input, .description-input {
    margin: 10px !important;
    width: 97% !important;
}

.dates-picker {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.local-type-input {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date-picker, .local-input, .type-input {
    margin: 10px !important;
    width: 100% !important;
}

.css-3ejzzx:first-of-type {
    overflow: hidden;
}

.css-v1j55f {
    max-width: 100% !important;
}

.calendar-extra-components{
    font-size: 15px;
    text-align: left;
    margin: 5px;
    max-width: 500px;
}

.calendar-extra-components .title{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0 !important;
}

.image-box {
    position: relative;
    border-radius: 70px;
    min-width: 220px;
    max-height: 350px;
    min-height: 220px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50px;
    border-style:solid;
    border-color: #FFFFFF;
    height: 30vw;
    width: 50vw;
    overflow: hidden;
    margin: 10px;
  }  
  
  .image-box-image {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;
  }  

  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(37, 64, 97, 0.3);
    z-index: 1;
  }
  
  .image-box-image-icon {
    position: absolute;
    top: 40%;
    left: 45%;
    z-index: 5;
  }
  
  .image-btn {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 15;
  }
  
  .image-box-event {
    position: relative;
    border-radius: 10px;
    min-width: 220px;
    max-height: 350px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-style:solid;
    border-color: #FFFFFF;
    height: 200px;
    overflow: hidden;
  }  

  .calendar-event-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .calendar-event-info-type, .calendar-event-info-location  {
    color: '#4D4D4D' !important;
    font-size: '15px';
  }

  .calendar-extra-components p {
    overflow-wrap: break-word;
    max-width: 500px;
  }

  .calendar-extra-components a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .css-fvtpii-MuiTypography-root {
    max-width: 500px;
  }
.btn-modal{
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.w-70 {
  width: 70%;
  font-style: bold;
}
#opportunities {
  height: 97%;
  width: 90%;
}

#opportunities-list {
  overflow-y: auto;
}

.opportunity-icon {
  background: #DBDFE3;
}

.opportunity-title {
  font-style: normal;
  font-weight: 700;
  font-size: 23px;
}

.opportunity-subtitle {
  font-weight: 700;
  font-size: 18px;
}

.opportunity-bar {
  width: 100px;
  height: 20px;
  margin-left: 6px;
  display: inline-block;
}

.green {
  background: #7BC950;
}

.yellow {
  background: #EEF15F;
}

.red {
  background: #8A3024;
}

#opportunity {
  height: 43vh;
  font-size: 20px;
}

.opportunity-description {
  font-size: 20px;
  font-weight: 400;
  width: 90%;
}

.opportunity-bold {
  font-weight: 700;
  font-size: 20px;
}

.opportunity-pdf {
  font-size: small;
  font-weight: 700;
  line-break: anywhere;
}
#configurations {
  height: 43vh;
}

.profile-picture {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: contain;
}

.choose-pic-btn {
  border: 2px solid #0060AE;
  color: #0060AE;
  font-weight: 500;
  font-size: 20px;
  min-height: 50%;
}

.change-pass-btn {
  font-weight: 500;
  font-size: 20px;
  background: #8AB0D0;
  border: 2px solid #0060AE;
  color: white;
  min-height: 50%;
}

.input-label {
  font-weight: 700;
  font-size: 19px;
  color: #4D4D4D;
}

.profile-input {
  background: #F5F5F7;
  border: 0.2px solid #E6E6E6;
  box-sizing: border-box;
  box-shadow: 0px 0.1px 3px rgba(0, 0, 0, 0.25);
  width: 70%;
  height: 50px;
  font-weight: 400;
  font-size: 20px;
  color: rgba(77, 77, 77, 0.9);
}

.blue-background {
  background: #E0E7EF;
}

.border-blue {
  border: 2px solid #8AB0D0;
}

.bold {
  font-weight: bold;
}

.config-row {
  width: 90%;
}

.icon {
  font-size: 40px;
}

.add-btn:hover {
  cursor: pointer;
}

.rdt_TableRow,
.rdt_TableHeadRow {
  background: #9cbadd;
  border: 2px solid #8AB0D0;
}

.hidden {
  visibility: hidden;
}

.carousel-item-config {
  height: 300px;
}

.image-box-image {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  text-align: center;
}  

.no-margin {
  margin: 0;
  margin-left: 10%;
  padding: 0;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.gallery-icon {
  font-size: 40px;
}

.member-partner-image {
  max-height: 70px;
  max-width: 200px;
}

.gallery-carousel > .carousel-control-prev {
  width: 32px;
}

.gallery-carousel > .carousel-control-next {
  width: 32px;
}

.gallery-carousel > .carousel-indicators {
  bottom: -15%;
}

.mobinov-reserved h5 {
    font-weight: bold;
    color: #4D4D4D;
}

.mobinov-reserved {
    width: 290px;
    height: 200px;
    margin: 17px 28px;
    background-color: #C4C4C4;
    padding: 0px 26px;
    cursor: pointer;
}

.reserved-sidebar-icon {
    max-width: 30px;
    max-height: 30px;
    -webkit-filter: brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(1225%) hue-rotate(155deg) brightness(89%) contrast(92%);
            filter: brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(1225%) hue-rotate(155deg) brightness(89%) contrast(92%);
}

.custom-a {
    color: var(--mobinov-grey-color);
    text-decoration: none;
}

.sidebar-text:hover, .sidebar-text-small:hover {
    color: var(--mobinov-blue-color);
}

.sidebar-text {
    font-size: 22px;
    font-weight: 600;
}

.sidebar-text-small {
    font-size: 17px;
    font-weight: 500;
}

.sidebar-selected {
    color: white;
    background-color: #8AB0D0;
}

.sidebar-selected-small {
    background-color: rgba(138, 176, 208, 0.3);
}
.blue-table-header {
  background: #8AB0D0;
  font-size: 22px;
}

.blue-table-row {
  background: #F5F5F7;
  font-size: 17px;
}

.border-blue {
  border: 2px solid #8AB0D0;
}

.table {
  width: 90%;
}
#contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#contacts-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.image {
    width: 20px;
    height: 15px;
}

.search-image {
    position: absolute;
    right: 5px;
    width: 25px;
}

.image-square {
    width: 20px;
    height: 20px;
}

.margin-img {
    margin-right: 10px;
}

.profile {
    max-width: 100px;
    max-height: 100px;
    border: 2px solid #C4C4C4;
}

@media only screen and (min-width: 1000px) {
    .contact {
        margin: 0px 15px 15px 0px;
        padding: 10px 10px 10px 10px;
        background: #DBDFE3;
        border: 2px solid #C4C4C4;
        border-radius: 2px;
        width: 45%;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        min-width: 400px;
    }
}

@media only screen and (max-width: 992px) {
    .contact {
        margin: 0px 0px 15px 0px;
        padding: 10px 10px 10px 10px;
        background: #DBDFE3;
        border: 2px solid #C4C4C4;
        border-radius: 2px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        min-width: 330px;
    }
}


.contact-unique {
    margin: 0px 15px 15px 0px;
    padding: 10px 10px 10px 10px;
    background: #DBDFE3;
    border: 2px solid #C4C4C4;
    border-radius: 2px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}

.grey-card {
    color: #4D4D4D;
}

.info-contacts {
    font-size: 15px;
}

.margin {
    margin: 0px 30px 0px 10px;
}

.search {
    width: 333px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}
