.service-card {
    background-color: #DBDFE3;
    min-height: 190px;
    vertical-align: middle;
}

.service-card:hover {
    background-color: rgba(0, 96, 174, 0.2);
}

@media only screen and (max-width: 992px) {
    .service-card {
        min-height: 200px;
    }
}

@media only screen and (max-width: 1200px) {
    .service-card {
        min-height: 236px;
    }
}