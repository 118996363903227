.list-button, .calendar-button {
    background-color: rgba(255, 255, 255, 0) !important;
    border-color: rgba(255, 255, 255, 0) !important;
    font-weight: 600;
    font-size: 23px;
    margin: 2px;
}

.list-button:hover, .calendar-button:hover {
    background-color: white !important;
    color: #0060AE !important;
    border-color: white !important;
}

.list-button:focus, .calendar-button:focus {
    background-color: white;
    color: #0060AE;
    border-color: white;
}

.title-input, .description-input {
    margin: 10px !important;
    width: 97% !important;
}

.dates-picker {
    margin: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.local-type-input {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.date-picker, .local-input, .type-input {
    margin: 10px !important;
    width: 100% !important;
}

.css-3ejzzx:first-of-type {
    overflow: hidden;
}

.css-v1j55f {
    max-width: 100% !important;
}

.calendar-extra-components{
    font-size: 15px;
    text-align: left;
    margin: 5px;
    max-width: 500px;
}

.calendar-extra-components .title{
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0 !important;
}

.image-box {
    position: relative;
    border-radius: 70px;
    min-width: 220px;
    max-height: 350px;
    min-height: 220px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-radius: 50px;
    border-style:solid;
    border-color: #FFFFFF;
    height: 30vw;
    width: 50vw;
    overflow: hidden;
    margin: 10px;
  }  
  
  .image-box-image {
    position: relative;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    text-align: center;
  }  

  .back {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgb(37, 64, 97, 0.3);
    z-index: 1;
  }
  
  .image-box-image-icon {
    position: absolute;
    top: 40%;
    left: 45%;
    z-index: 5;
  }
  
  .image-btn {
    position: relative;
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 0;
    z-index: 15;
  }
  
  .image-box-event {
    position: relative;
    border-radius: 10px;
    min-width: 220px;
    max-height: 350px;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    border-style:solid;
    border-color: #FFFFFF;
    height: 200px;
    overflow: hidden;
  }  

  .calendar-event-info {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .calendar-event-info-type, .calendar-event-info-location  {
    color: '#4D4D4D' !important;
    font-size: '15px';
  }

  .calendar-extra-components p {
    overflow-wrap: break-word;
    max-width: 500px;
  }

  .calendar-extra-components a {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .css-fvtpii-MuiTypography-root {
    max-width: 500px;
  }