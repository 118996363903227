#configurations {
  height: 43vh;
}

.profile-picture {
  height: 80px;
  width: 80px;
  border-radius: 50%;
  object-fit: contain;
}

.choose-pic-btn {
  border: 2px solid #0060AE;
  color: #0060AE;
  font-weight: 500;
  font-size: 20px;
  min-height: 50%;
}

.change-pass-btn {
  font-weight: 500;
  font-size: 20px;
  background: #8AB0D0;
  border: 2px solid #0060AE;
  color: white;
  min-height: 50%;
}

.input-label {
  font-weight: 700;
  font-size: 19px;
  color: #4D4D4D;
}

.profile-input {
  background: #F5F5F7;
  border: 0.2px solid #E6E6E6;
  box-sizing: border-box;
  box-shadow: 0px 0.1px 3px rgba(0, 0, 0, 0.25);
  width: 70%;
  height: 50px;
  font-weight: 400;
  font-size: 20px;
  color: rgba(77, 77, 77, 0.9);
}

.blue-background {
  background: #E0E7EF;
}

.border-blue {
  border: 2px solid #8AB0D0;
}

.bold {
  font-weight: bold;
}

.config-row {
  width: 90%;
}

.icon {
  font-size: 40px;
}

.add-btn:hover {
  cursor: pointer;
}

.rdt_TableRow,
.rdt_TableHeadRow {
  background: #9cbadd;
  border: 2px solid #8AB0D0;
}

.hidden {
  visibility: hidden;
}

.carousel-item-config {
  height: 300px;
}

.image-box-image {
  position: relative;
  height: 100%;
  background-size: cover;
  background-position: 50% 50%;
  text-align: center;
}  

.no-margin {
  margin: 0;
  margin-left: 10%;
  padding: 0;
  height: fit-content;
}

.gallery-icon {
  font-size: 40px;
}

.member-partner-image {
  max-height: 70px;
  max-width: 200px;
}

.gallery-carousel > .carousel-control-prev {
  width: 32px;
}

.gallery-carousel > .carousel-control-next {
  width: 32px;
}

.gallery-carousel > .carousel-indicators {
  bottom: -15%;
}
