.blue-table-header {
  background: #8AB0D0;
  font-size: 22px;
}

.blue-table-row {
  background: #F5F5F7;
  font-size: 17px;
}

.border-blue {
  border: 2px solid #8AB0D0;
}

.table {
  width: 90%;
}