.card-img-project {
    max-width: 499px;
    max-height: 298px;
    background-color: rgba(230, 230, 230, 0.8);
}

.card-project {
    max-width: 500px;
    min-height: 500px;
    max-height: 300px;
    min-height: 300px;
    align-items: center ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
