#contacts {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

#contacts-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}

.image {
    width: 20px;
    height: 15px;
}

.search-image {
    position: absolute;
    right: 5px;
    width: 25px;
}

.image-square {
    width: 20px;
    height: 20px;
}

.margin-img {
    margin-right: 10px;
}

.profile {
    max-width: 100px;
    max-height: 100px;
    border: 2px solid #C4C4C4;
}

@media only screen and (min-width: 1000px) {
    .contact {
        margin: 0px 15px 15px 0px;
        padding: 10px 10px 10px 10px;
        background: #DBDFE3;
        border: 2px solid #C4C4C4;
        border-radius: 2px;
        width: 45%;
        height: fit-content;
        min-width: 400px;
    }
}

@media only screen and (max-width: 992px) {
    .contact {
        margin: 0px 0px 15px 0px;
        padding: 10px 10px 10px 10px;
        background: #DBDFE3;
        border: 2px solid #C4C4C4;
        border-radius: 2px;
        height: fit-content;
        min-width: 330px;
    }
}


.contact-unique {
    margin: 0px 15px 15px 0px;
    padding: 10px 10px 10px 10px;
    background: #DBDFE3;
    border: 2px solid #C4C4C4;
    border-radius: 2px;
    height: fit-content;
}

.grey-card {
    color: #4D4D4D;
}

.info-contacts {
    font-size: 15px;
}

.margin {
    margin: 0px 30px 0px 10px;
}

.search {
    width: 333px;
    margin-bottom: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}