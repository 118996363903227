.card-gallery {
    max-width: 500px;
    min-height: 500px;
    max-height: 300px;
    min-height: 300px;
    align-items: center ;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;
    filter: drop-shadow(0px 0.2px 10px rgba(0, 0, 0, 0.25));
}