.enterprises {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
}

#members .container {
    max-width: 1060px;
}

#members .row h2 {
    font-size: 25px;
}

#members .row .text-center:hover {
    color: #0060AE;
    cursor: pointer;
}

a.partner-box {
    display: inline-flex;
    padding: 30px 20px;
}

.text-selected {
    color: #0060AE;
}

button {
    /* all: unset; */
    background: transparent;
    border: 0px;
    font-size: 25px;
    font-weight: 600;
}

.members-card {
    width: 200px;
    margin: 30px 12px;
    background-color: rgba(230, 230, 230, 0.8);
    box-shadow: 0 1px 10px rgb(0 0 0 / 25%);
}