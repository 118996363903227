.nav-item {
  font-family: Roboto;
  font-size: 20px;
  line-height: 23px;
  color: #6C6C6C;
}

.nav-item:hover {
  cursor: pointer;
  font-weight: bold;
  text-decoration: underline;
}


.navbar {
  height: 100%;
  background: linear-gradient(0deg, rgba(0, 96, 174, 0.05), rgba(0, 96, 174, 0.05)), #E6E6E6;
  box-shadow: 0px 1px 10px rgba(0, 0, 0, 0.25);
}


#logo {
  margin-left: 2em;
}


.nav-item:hover .sub-navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 100;
}

.nav-item:hover .block-navbar {
  display: flex;
  position: absolute;
  height: 2em;
  width: 10%;
}

.block-navbar:hover {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 100;
}


.sub-navbar:hover .sub-navbar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  z-index: 100;
}

.sub-navbar {
  display: none;
  position: absolute;
  width: 100%;
  left: 0;
  top: 105px;
  height: 80px;
  background: #0060AE !important;
}

.sub-navbar-content a {
  margin-right: 3em;
  text-decoration: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  color: white;
}

.sub-navbar-content a:hover {
  text-decoration: underline;
}

#pt {
  font-weight: bold;
}

#en {
  font-weight: normal;
}

@media only screen and (max-width: 992px) {
  .sub-navbar-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    height: max-content;
    padding-left: 10%;
  }

  .sub-navbar-content a {
    line-height: 36px;
  }

  .sub-navbar {
    top: 413px;
    height: max-content;
  }

  .nav-item:hover .block-navbar {
    height: 30vh;
    width: 100%;
  }
}