.reserved-sidebar-icon {
    max-width: 30px;
    max-height: 30px;
    filter: brightness(0) saturate(100%) invert(32%) sepia(0%) saturate(1225%) hue-rotate(155deg) brightness(89%) contrast(92%);
}

.custom-a {
    color: var(--mobinov-grey-color);
    text-decoration: none;
}

.sidebar-text:hover, .sidebar-text-small:hover {
    color: var(--mobinov-blue-color);
}

.sidebar-text {
    font-size: 22px;
    font-weight: 600;
}

.sidebar-text-small {
    font-size: 17px;
    font-weight: 500;
}

.sidebar-selected {
    color: white;
    background-color: #8AB0D0;
}

.sidebar-selected-small {
    background-color: rgba(138, 176, 208, 0.3);
}