.whoarewe-pdf-button {
    min-width: 160px;
}

.whoarewe-card-header {
    background: linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7; 
    min-height: 155px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.whoarewe-card-body {
    background: linear-gradient(0deg, rgba(0, 96, 174, 0.2), rgba(0, 96, 174, 0.2)), #F5F5F7;
    min-height: 280px;
}

.whoarewe-icon {
    width: 28px;
    height: 28px;
}

.whoarewe-card-date {
    font-weight: 600;
    font-size: 22px;
}

.whoarewe-carousel {
    position: relative;
}

.whoarewe-button-group {
    position: absolute;
    width: 100%;
    top: 48%;
}

.whoarewe-carousel-icon {
    height: 30px;
}

.whoarewe-button-group button {
    background: linear-gradient(0deg, #E6E6E6, #E6E6E6), #F5F5F7;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.3));
    
    height: 50px;
    width: 35px;

    position: absolute;
}

.whoarewe-button-group button:nth-child(1) {
    left: -4%;
}

.whoarewe-button-group button:nth-child(2) {
    right: -4%;
}

.whoarewe-button-group button.disable {
    visibility: hidden;
}

@media only screen and (max-width: 992px) {
    .whoarewe-card-body {
        height: 230px; 
    }
    .whoarewe-button-group button:nth-child(1) {
        left: -6%;
    }
    
    .whoarewe-button-group button:nth-child(2) {
        right: -6%;
    }
}

  @media only screen and (max-width: 576px) {
    .whoarewe-card-text {
        font-size: 19px;
    }
}