.mobinov-reserved h5 {
    font-weight: bold;
    color: #4D4D4D;
}

.mobinov-reserved {
    width: 290px;
    height: 200px;
    margin: 17px 28px;
    background-color: #C4C4C4;
    padding: 0px 26px;
    cursor: pointer;
}
