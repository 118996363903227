footer {
    width: 100%;
    background: #DBDFE3;

    font-style: normal;
    font-size: 10px;
    color: var(--mobinov-grey-color);
}

footer a {
    text-decoration: none;
    text-align: center;
    color: var(--mobinov-grey-color);
}

footer a:hover {
    color: var(--mobinov-blue-color);
}

.footer-link a {
    font-size: 1.8em;
    font-weight: 600;
}

.social-media-icon {
    width: 48px;
    height: 48px;
}

.footer-contacts {
    font-size: 1.6em;
    font-weight: 400;
}

.contact-icon {
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.contact-icon.disabled {
    visibility: hidden;
}

.authorities {
    font-size: 1.8em;
    font-weight: 700;
    text-align: center;
}

.authorities img{
    width: 430px;
}
